import OrderBackCard from "modules/base/components/priceCard/returnOrder/orderBackCard.vue";
export default {
    components: {
        OrderBackCard,
    },
    computed: {
        items() {
            if(!this.propsValue) return []
            return this.propsValue.items
        },
    },
}